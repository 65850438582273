import mainLogo from "../assets/picture/logo.svg";

const Logo = () => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-x-[20px]">
      <div>
        <img src={mainLogo} alt="" />
      </div>
      <div className="bg-black w-[2px] h-[36px] hidden sm:block"></div>
      <span className="lexend-500 text-[32px] xs:text-[36px] sm:text-[48px]">
        SWISSBLADE NFT
      </span>
    </div>
  );
};

export default Logo;
