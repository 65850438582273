import { useMemo } from "react";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { bscTestnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./pages/Landing";

function App(): JSX.Element {
  // 0. Setup queryClient
  const projectId = "e0fb9e301fe36fc3e2392758c7b9fa28";

  const queryClient = useMemo(() => new QueryClient(), []);

  const chains = useMemo(
    () =>
      [
        {
          ...bscTestnet,
          rpcUrls: {
            default: {
              http: ["https://rpc.ankr.com/bsc_testnet_chapel"],
            },
          },
        },
      ] as const,
    []
  );

  const metadata = useMemo(
    () => ({
      name: "Web3Modal",
      description: "Web3Modal Example",
      url: "https://web3modal.com", // origin must match your domain & subdomain
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    }),
    []
  );

  const config = useMemo(() => {
    // console.log("Creating WagmiConfig");
    return defaultWagmiConfig({
      projectId,
      chains,
      metadata,
    });
  }, [projectId, chains, metadata]);

  useMemo(() => {
    console.log("Creating Web3Modal");
    return createWeb3Modal({
      wagmiConfig: config,
      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      enableOnramp: true, // Optional - false as default
    });
  }, [projectId, config]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Landing />
        <ToastContainer />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
