import React, { useEffect, useState } from "react";
import CountDown from "./CountDown";
import Footer from "../components/Footer";
import { useReadContract } from "wagmi";
import { abi, nftAddress } from "../utils";
import moment from "moment";

function Landing() {
  // const targetDate = useMemo(() => new Date(2024, 2, 30, 0, 0, 0), []); //  March 30th, 2024, 12:00:00 AM
  const [targetDate, setTargetDate] = useState(20); // 20 seconds

  // @ts-ignore
  const { data: roundTimes }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "getRoundTimes",
    args: [],
  } as const);

  // @ts-ignore
  const { data: currentRound }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "getCurrentRound",
    args: [],
  } as const);

  const calculateTimer = () => {
    const curRound = Number(currentRound?.toString() || 0);
    const currentTime = moment();
    const gtdEndTime = moment.unix(Number(roundTimes?.[1]?.toString() || 0));
    const gtdStartTime = moment.unix(Number(roundTimes?.[0]?.toString() || 0));
    const fcfsStartTime = moment.unix(Number(roundTimes?.[2]?.toString() || 0));
    const fcfsEndTime = moment.unix(Number(roundTimes?.[3]?.toString() || 0));

    if (curRound === 0) {
      if (currentTime.isBefore(gtdStartTime)) {
        return gtdStartTime.diff(currentTime, "seconds") || 0;
      } else if (currentTime.isBefore(fcfsStartTime)) {
        return fcfsStartTime.diff(currentTime, "seconds") || 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setTargetDate(calculateTimer());
  }, [roundTimes, currentRound]);

  return (
    <div className="max-w-screen-2xl mx-auto py-[20px] sm:py-[40px] md:py-[70px] xl:py-[100px]">
      <CountDown key={123} endDate={Date.now() + targetDate * 1000} />
      <Footer />
    </div>
  );
}

export default Landing;
